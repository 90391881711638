import { ResponsePlaceToPay } from './../model/Response';
import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { TokenService } from './token.service';
import { RequestEstadoTrans, RequestPlaceToPay } from './../model/Request';
import { RequestInfoPTP } from '../model/DTO/clasesDTO';
@Injectable({
  providedIn: 'root'
})
export class PlaceToPayService {

  public url = environment.URL_SERVICE + "PlaceToPay";
  public headers = new HttpHeaders(
    {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": "Bearer" + sessionStorage.getItem("token")
    }
  );

  constructor(private httpClient: HttpClient,private tokenServ:TokenService) { }

  crearSesionPago(request:RequestPlaceToPay,sAuthToken: string){
    let path=this.url+"/crearSesionPago";
    let datosPersona = JSON.parse(sessionStorage.getItem("datosUsuario"));
    request.xiTipoIdent = datosPersona.tipoIdent;
    request.cIdent = datosPersona.ident;
    this.headers = this.headers.set('Authorization', 'Bearer' + sAuthToken);
    return this.httpClient.post<ResponsePlaceToPay>(path, JSON.stringify(request), { headers: this.headers });
  }

  cargarInfoPago(idPago:string,sAuthToken:string){
    let path=this.url+"/obtenerInfoPago?xIdPago="+idPago;
    this.headers = this.headers.set('Authorization', 'Bearer' + sAuthToken);
    return this.httpClient.post<ResponsePlaceToPay>(path, null, { headers: this.headers });
  }

  informarGestor(sNombreCliente: string,sMetodoPago:string,sReferencia:string,sEstado:string,sAuthToken:string){
    let path=this.url+"/informarGestor";
    path+="?sNombreCliente="+sNombreCliente;
    path+="&sMetodoPago="+sMetodoPago;
    path+="&sReferencia="+sReferencia;
    path+="&sEstado="+sEstado;
    this.headers = this.headers.set('Authorization', 'Bearer' + sAuthToken);
    return this.httpClient.post<any>(path,null, { headers: this.headers });
  }

  actualizarInfoPago(request:RequestEstadoTrans){
    let path=this.url+"/notificacionEstadoTransaccion";
    return this.httpClient.post<ResponsePlaceToPay>(path, JSON.stringify(request), { headers: this.headers });
  }

  consultarTransacciones(codCredito){
    let path = this.url+"/consultarTransacciones";
    path+="?xCodUsuario="+this.tokenServ.getCodUser();
    path+="&xCodCredito="+codCredito;
    this.headers = this.headers.set('Authorization', 'Bearer');
    return this.httpClient.post<ResponsePlaceToPay>(path, null, { headers: this.headers });
  }

  consultarTransaccionesAdmin(codCredito,codUsuario,sAuthToken:string){
    let path = this.url+"/consultarTransacciones";
    path+="?xCodUsuario="+codUsuario;
    path+="&xCodCredito="+codCredito;
    this.headers = this.headers.set('Authorization', 'Bearer' + sAuthToken);
    return this.httpClient.post<ResponsePlaceToPay>(path, null, { headers: this.headers });
  }

  actualizarTransacciones(codCredito,sAuthToken:string){
    let path = this.url+"/actualizarTransacciones";
    path+="?xCodUsuario="+this.tokenServ.getCodUser();
    path+="&xCodCredito="+codCredito;
    this.headers = this.headers.set('Authorization', 'Bearer' + sAuthToken);
    return this.httpClient.post<ResponsePlaceToPay>(path, null, { headers: this.headers });
  }
}
