import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { ResponseGenerica, ResponseUsuario } from './../model/Response';
import { RequestClaveNueva, RequestOlvidoClave } from '../model/Request';
import { TokenService } from './token.service';
import { PersonaNueva, UsuarioNuevo } from 'src/app/model/Formularios';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  public url = environment.URL_SERVICE+"Usuario/";
  public headers = new HttpHeaders(
    {
      "Content-Type": "application/json;charset=UTF-8"
    }
  );

  constructor(private httpClient: HttpClient,private tokenSer:TokenService) { }

  listarUsuarios(sAuthToken: string){
    let path=this.url+"listarUsuarios";
    this.headers = this.headers.set('Authorization', 'Bearer' + sAuthToken);
    return this.httpClient.post<ResponseUsuario>(path, null, { headers: this.headers });
  }

  buscarUsuarioPorTipoIdent(xiTipoIdent,xIdent,sAuthToken: string){
    let path=this.url+"buscarUsuarioPorTipoIdent";
    path+="?xiTipoIdent="+xiTipoIdent;
    path+="&xIdent="+xIdent;
    this.headers = this.headers.set('Authorization', 'Bearer' + sAuthToken);
    return this.httpClient.post<ResponseUsuario>(path, null, { headers: this.headers });
  }

  cambiarContraseniaAdmin(request:RequestClaveNueva,sAuthToken:string){
    // request.iCodUsuario = this.tokenSer.getCodUser();
    let path=this.url+"cambiarContraseniaAdmin";
    this.headers = this.headers.set('Authorization', 'Bearer' + sAuthToken);
    return this.httpClient.post<ResponseGenerica>(path, JSON.stringify(request), { headers: this.headers });
  }

  cambiarContraseniaAdminAUsuarios(request:RequestClaveNueva,sAuthToken:string){
    let path=this.url+"cambiarContraseniaAdminAUsuarios";
    this.headers = this.headers.set('Authorization', 'Bearer' + sAuthToken);
    return this.httpClient.post<ResponseGenerica>(path, JSON.stringify(request), { headers: this.headers });
  }

  crearUsuario(persona: any,sAuthToken:string){
    let path = this.url + 'registrarUsuario';
    this.headers = this.headers.set('Authorization', 'Bearer' + sAuthToken);
    return this.httpClient.post(path, persona, { headers: this.headers});
  }
  registrarPersona(persona: UsuarioNuevo,sAuthToken:string){
    let path = this.url + 'registrarPersona';
    this.headers = this.headers.set('Authorization', 'Bearer' + sAuthToken);
    return this.httpClient.post(path, persona, { headers: this.headers});
  }
}
