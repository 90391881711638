import { expr } from "jquery";

export class FormularioLogin{
  tipoIdent:any;
  ident:any;
  pass:any;
}

export class OpcionMenu{
  icono:any;
  nombre:any;
  ruta:any;
}

export class MenuDetalleCredito{
  idMenu:number;
  nombre:string;
  ruta:string;
  revisado:boolean;
}

export class ItemEstado{
  public cEstado:any;
  public cNomEstado:any;
}

export class DeclaracionSaludDTO{
  public idItem:number;
  public idItemString:string;
  public nombre:string;
  public checked:boolean;
}

export class ParametroDTO{
  public codigo:number;
  public descripcion:string;
  public valor:string;
  public estado:string;
}


export class UsuarioNuevo {
  pwsTipodepersona?: string;
  pwsIdentific?: string;
  pwnTipoiden?: string;
  pwdFecexp?: string;
  pDfsNatNombres?: string;
  pDfsNatApellidos?: string;
  pwsDireccion?: string;
  pwnCiudad?: string;
  pwsTelefono?: string;
  pwsDirecelec?: string;
  tipoCanal?: string;
}


export class PersonaNueva {
  public iTipoIdent: number;
  public cIdentificacion: string;
  public cNombres: string
  public cApellidos: string;
  public cPerfil: number;
}
