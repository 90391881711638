import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ComunicacionService {

  public url = environment.URL_SERVICE + 'Comunicacion';
  public headers = new HttpHeaders(
    {
      "Content-Type": "application/json",
      "Authorization": "Bearer" + sessionStorage.getItem("token")
    }
  );


  constructor(private http: HttpClient) { }


  enviarComunicado(xCodSol,xTipoMensaje ,xMensaje,sAuthToken: string){
    let path=this.url+"/WSComunicacion/saveComunicacion"
    path += `?xCodSolicitud=${xCodSol}`;
    path += `&xCodTipoCom=${xTipoMensaje}`;
    path += `&xMensaje=${xMensaje}`;
    this.headers = this.headers.set('Authorization', 'Bearer' + sAuthToken);
    return this.http.post(path,{headers:this.headers});
  }

  cargarTodas(){
    let path=this.url+"/WSComunicacion/buscarTodas";
    return this.http.post(path,{headers:this.headers});
  }

  cargarPorSolCred(xCodSol,sAuthToken: string){
    let path=this.url+"/WSComunicacion/buscarPorIdSol?xCodSolicitud="+xCodSol;
    this.headers = this.headers.set('Authorization', 'Bearer' + sAuthToken);
    return this.http.post(path,{headers:this.headers});
  }

}
